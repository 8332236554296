import React from 'react';
import PropTypes from "prop-types";
import './Login.less';
import { Button, Grid, Image, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RiEmail, RiPassword} from "../../../shared/components/form-components";
// import {LOGIN_REMEMBER_ME} from "../../../shared/storage-keys";
// import {getJSONItem, removeItem, setJSONItem} from "../../../shared/storage";
import {doLogin as doLoginAction} from "../../../shared/redux/actions/authAction";
import logo from '../../../assets/images/logo.svg';
import flexLogo from '../../../assets/images/flex-logo.svg';
import {callAPI} from "../../../shared/api-call";
import RiForm from "../../../shared/components/form-components/RiForm2";
// import { decryptPassword, encryptPassword } from '../../../shared/utils';

const OnResize = ({windowSize:{height}})=>{
  setTimeout(()=>{
    const layoutContainer = document.getElementById("login-page");
    if(layoutContainer){
      layoutContainer.style.minHeight = `${height}px`;
    }
  }, 20);
  return <div style={{display:'none'}}/>
}

OnResize.propTypes = {
  windowSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired
};

const mapStateToProps =  (state) => ({windowSize: state.commonUI.windowSize});
const FinalOnResize =  connect(mapStateToProps)(OnResize);


// const rememberData = (loginData)=>{
//   if(loginData.rememberMe) {
//     setJSONItem(LOGIN_REMEMBER_ME, { ...loginData, password: encryptPassword(loginData.password)});
//   } else {
//     removeItem(LOGIN_REMEMBER_ME);
//   }
// };

// const getOldLoginData = ()=> {
//   const data = getJSONItem(LOGIN_REMEMBER_ME);
//   return data && {
//     ...data,
//     password: decryptPassword(data.password)
//   }
// };
// ({username:'ddebhi@flextg.com', password:'*gaioka@36'})

const Login =  ({doLogin}) => {
  const onSubmit = async (loginData)=>{
    // rememberData(loginData);
    try{
      const loginResponse = await callAPI({method: 'post', url: '/login', body:loginData});
      doLogin(loginResponse);
    } catch(e){
      // Do Nothing
    }
  };

  return (<Grid textAlign='center' id='login-page' verticalAlign='middle' className="login-form-container">
    <Grid.Column style={{maxWidth: 420}} className="login-form">
      <FinalOnResize />
      <div className="image-container">
        <Image src={logo}/>
      </div>
      <RiForm
        onSubmit={onSubmit}
        // initialValues={getOldLoginData()}
        >
        {
          ({ submitting }) =>
            <Segment stacked>
              <h2>Login to your account</h2>
                <RiEmail name='username' placeholder='Your Email'/>
                <RiPassword name='password' placeholder='Password' required/>
              <Button primary fluid size='large' type="submit" disabled={submitting}>Login</Button>
            </Segment>
        }
      </RiForm>
      <div className="footer">
        <span className="text">
          ©{new Date().getFullYear()} Flex Technology Group
        </span>
        <Image src={flexLogo}/>
      </div>
    </Grid.Column>
  </Grid>);
};

Login.propTypes = {
  doLogin: PropTypes.func.isRequired
};

export default connect(null, { doLogin: doLoginAction })(Login);
