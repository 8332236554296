export * from '../env/dev.js';

export const GRID = {
  PAGE_SIZE: 25
};

export const ORDER_TYPES = [
  {name:'Equip - Cash', id:101},
  {name:'Equip - Lease', id:105}
]

export const VALIDATION_PATTERN = {
  EMAIL: new RegExp('^[a-zA-Z0-9_]([a-zA-Z0-9._+-]|)*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
  NUMBER: new RegExp('^\\d+$'),
  FLOAT: new RegExp('^\\d*(\\.\\d+)?$'),
  ZIP:new RegExp('^\\d{5}(?:[-\\s]\\d{4})?$'),
  PHONE:new RegExp('^\\d{10}?$')
}

export const INSTRUCTIONS = {
  LOCATION: ['Complete the contact name, email, phone for each location you are ordering equipment for',
    'Note the Location ID for the location that is receiving equipment, you will need to reference that number on the Equipments tab',
    'General Instructions - Once this form is filled out, save, and upload to DealDash to continue processing your order'],
  EQUIPMENT: ['Equipment ID is the number for the order, this does not refer to the tag number that will be placed on the device',
    'Enter the location ID from the LOCATIONS tab. Fill out the remaining columns per your order'],
  ACCESSORY: ['Enter the Equipment ID from the EQUIPMENTS tab. Fill in the rest of the information']
};
